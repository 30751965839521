import {
  Box,
  Divider,
  Heading,
  Input,
  Badge,
  IconButton,
  Text,
  IconElement,
} from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { FunctionComponent, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DATA_HOOKS } from '../../dataHooks';
import css from './index.module.scss';
import { RenderHTML } from '../RenderHTML';
import {
  ArrowRight as ArrowRightIcon,
  ArrowLeft as ArrowLeftIcon,
  HintSmall as HintSmallIcon,
  Search as SearchIcon,
  AppSmall as AppSmallIcon,
  ChevronDown as ChevronDownIcon,
  PremiumSmall as PremiumSmallIcon,
  OnlineProgramsSmall as OnlineProgramsSmallIcon,
  EmailSmall as EmailSmallIcon,
  GoogleAdsSmall as GoogleAdsSmallIcon,
} from '@wix/wix-ui-icons-common';
import { pushUrl } from '../InnerLink';
import { isRTL } from '../../utils/internationalization';
import { useRouter } from 'next/router';
import { handleLongFirstName } from '../../utils/text';
import { BI, PAGES, SESSION_STORAGE } from '../../constants';
import { useBI } from '../../hooks/useBI';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { Context } from '../../context';

export type KnowledgeAgentSearchHeroProps = {
  onScrollDown: () => void;
};

type SearchBadge = {
  key: string;
  icon: IconElement;
};

export const MAX_SEARCH_INPUT_LENGTH = 100;
const mobileSuggestionLimit = 3;

export const BADGES: SearchBadge[] = [
  {
    key: 'homepage.knowledge-agent-search.suggestion-domain',
    icon: <OnlineProgramsSmallIcon />,
  },
  {
    key: 'homepage.knowledge-agent-search.suggestion-premium',
    icon: <PremiumSmallIcon />,
  },
  {
    key: 'homepage.knowledge-agent-search.suggestion-dynamic',
    icon: <AppSmallIcon />,
  },
  {
    key: 'homepage.knowledge-agent-search.suggestion-ads',
    icon: <GoogleAdsSmallIcon />,
  },
  {
    key: 'homepage.knowledge-agent-search.suggestion-email',
    icon: <EmailSmallIcon />,
  },
];

export const KnowledgeAgentSearchHero: FunctionComponent<
  KnowledgeAgentSearchHeroProps
> = ({ onScrollDown }) => {
  const { t } = useTranslation();
  const { isMobile, user, setSearchSessionId } = useContext(Context);
  const [hoveredBadgeIndex, setHoveredBadgeIndex] = useState<number>(-1);
  const [query, setQuery] = useState<string>('');
  const { locale } = useRouter();
  const { sendBIEvent } = useBI();

  const goToSearchResultsPage = async (overideQuery?: string) => {
    const isSuggestionBadgeClicked = overideQuery && overideQuery?.length > 0;
    sessionStorage.setItem(
      SESSION_STORAGE.KEYS.USER_SEARCHED,
      JSON.stringify({
        pageType: PAGES.HOME,
        inputType: isSuggestionBadgeClicked ? 'suggested question' : '',
      })
    );
    setSearchSessionId(uuidv4());

    if (isSuggestionBadgeClicked) {
      await pushUrl(`${locale}/search?term=${overideQuery}`);
    } else {
      await pushUrl(`${locale}/search?term=${encodeURIComponent(query)}`);
    }
  };

  const onScrollDownPage = () => {
    void sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.HOME,
        kb_lang: locale as string,
        clicked_item_type: BI.CLICKED_ITEM_TYPES.HOME_PAGE_EXPAND,
        clicked_text: BI.EXPLORE_MORE,
      })
    );
    onScrollDown();
  };

  return (
    <>
      <Box
        className={css.wrapper}
        dataHook={DATA_HOOKS.KNOWLEDGE_AGENT_SEARCH_HERO}
        direction="vertical"
        verticalAlign="middle"
        align="center"
      >
        <Heading
          dataHook={DATA_HOOKS.HOMEPAGE_TITLE}
          className={css.personalizedTitle}
        >
          {user ? (
            <RenderHTML
              html={t('homepage.knowledge-agent-search.personalized-title', {
                firstName: handleLongFirstName(user.firstName),
              })}
            />
          ) : (
            t('homepage.title')
          )}
        </Heading>
        <Box direction="vertical" className={css.searchBarWrapper}>
          <Box direction="vertical" className={css.searchBarWithHint}>
            <Box direction="horizontal" className={css.searchBar}>
              <Input
                size="large"
                autoFocus={true}
                maxLength={MAX_SEARCH_INPUT_LENGTH}
                dataHook={DATA_HOOKS.HOME_SEARCH_INPUT}
                className={css.searchInput}
                onChange={(e) => setQuery(e.target.value)}
                placeholder={
                  !isMobile
                    ? t('homepage.knowledge-agent-search.placeholder')
                    : t('homepage.knowledge-agent-search.placeholder-mobile')
                }
                prefix={
                  <Input.IconAffix>
                    <SearchIcon />
                  </Input.IconAffix>
                }
                suffix={
                  isMobile && (
                    <Input.IconAffix>
                      <IconButton
                        className={css.searchArrowIcon}
                        size="large"
                        dataHook={DATA_HOOKS.SEARCH_BUTTON}
                        onClick={() => goToSearchResultsPage()}
                      >
                        {isRTL(locale) ? <ArrowLeftIcon /> : <ArrowRightIcon />}
                      </IconButton>
                    </Input.IconAffix>
                  )
                }
                onEnterPressed={() => goToSearchResultsPage()}
                value={query}
              />
              {!isMobile ? (
                <IconButton
                  className={css.searchArrowIcon}
                  size="large"
                  dataHook={DATA_HOOKS.SEARCH_BUTTON}
                  onClick={() => goToSearchResultsPage()}
                >
                  {isRTL(locale) ? <ArrowLeftIcon /> : <ArrowRightIcon />}
                </IconButton>
              ) : null}
            </Box>
            <Box className={css.searchHint}>
              {!isMobile && <HintSmallIcon className={css.hintIcon} />}
              <Text size={isMobile ? 'tiny' : 'small'} className={css.hintText}>
                {t('homepage.knowledge-agent-search.hint')}
              </Text>
            </Box>
          </Box>
          <Box className={css.suggestionWrapper}>
            {BADGES.slice(
              0,
              isMobile ? mobileSuggestionLimit : BADGES.length
            ).map((badge: SearchBadge, index: number) => (
              <div
                className={css.suggestion}
                key={badge.key}
                onMouseEnter={() => setHoveredBadgeIndex(index)}
                onMouseLeave={() => setHoveredBadgeIndex(-1)}
                onClick={() => goToSearchResultsPage(t(badge.key))}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    void goToSearchResultsPage(t(badge.key));
                  }
                }}
                tabIndex={0}
              >
                <Badge
                  dataHook={DATA_HOOKS.SUGGESTION_BADGE}
                  skin={
                    index === hoveredBadgeIndex ? 'general' : 'neutralLight'
                  }
                  prefixIcon={badge.icon}
                >
                  {t(badge.key)}
                </Badge>
              </div>
            ))}
          </Box>
        </Box>

        <div
          data-hook={DATA_HOOKS.SCROLL_DOWN_ARROW}
          className={css.exploreMoreContainer}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onScrollDownPage();
            }
          }}
          onClick={onScrollDownPage}
        >
          <Box className={css.exploreMore} direction="vertical">
            <Text
              skin="primary"
              size={isMobile ? 'small' : 'medium'}
              className={css.exploreMoreText}
            >
              {t('homepage.knowledge-agent-search.explore-more')}
            </Text>
            <Box className={css.exploreMoreArrow}>
              <ChevronDownIcon className={css.exploreMoreIcon} />
            </Box>
          </Box>
        </div>
      </Box>
      <Box className={css.dividerContainer}>
        <Divider className={css.divider} direction="horizontal" />
      </Box>
    </>
  );
};
