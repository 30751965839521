/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  ArticleResolution,
  ArticleSortType,
  ArticleStatus,
  Label,
  SourceType,
} from '@wix/answers-api';
import { sortAndFilter } from '@wix/bi-logger-new-help-center/v2';
import { Box } from '@wix/design-system';
import {
  useExperiments,
  useHttpClient,
  useTranslation,
} from '@wix/fe-essentials-standalone';
import { isProduction } from '@wix/wix-run-mode';
import { useRouter } from 'next/router';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { animateScroll } from 'react-scroll';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Error } from '../../components/Error';
import { pushUrl } from '../../components/InnerLink';
import { RoadmapPageData } from '../../components/PageTemplate';
import {
  ALL_PRODUCTS_LABEL_ID,
  BI,
  EXPERIMENTS,
  LOCALES,
  MOCKED_ROADMAP_LABELS,
  PAGES,
} from '../../constants';
import { Context } from '../../context';
import { useBI } from '../../hooks/useBI';
import { useIsIntersecting } from '../../hooks/useIsIntersecting';
import {
  adminSearchArticles,
  getLabels,
  searchArticles,
} from '../../services/answers';
import {
  ALL_RESOLUTIONS,
  ArticleSearchResult,
  ArticleSearchResultItem,
  RoadmapFeatureResolution,
  RoadmapETAorVotedFilter,
  SortAndFilterBiMetaData,
  RoadmapFilter,
} from '../../types';
import { withAppServerProps } from '../../utils/appServerProps';
import {
  EventRoadmapData,
  FilterToName,
  getResolutionEtaFilters,
  getRoadmapLabels,
  tabToName,
} from '../../utils/roadmap';
import { isMobile, isSledUserAgent } from '../../utils/userAgent';
import { RoadmapArticleList } from '../../components/RoadmapArticleList';
import css from './index.module.scss';

export const PAGE_SIZE = 12;
export const ALL_PRODUCTS_LABEL = {
  name: 'All Products',
  id: ALL_PRODUCTS_LABEL_ID,
};

export const TEST_LABEL_ID = [
  'fe6b7bd2-7a44-4c89-bc79-b3c5dc683788',
  'c907fa60-1d37-40ac-a19c-e787750bc19f',
];

export const MAX_SEARCH_RESULTS = 996;

export type RoadmapPageProps = {
  searchResults: ArticleSearchResult;
  roadmapLabels: Label[];
  roadmapInitialFilter: RoadmapFilter;
};

const RoadmapPage: FunctionComponent<RoadmapPageProps> = ({
  searchResults,
  roadmapLabels,
  roadmapInitialFilter,
}) => {
  const isInitialMount = useRef(true);
  const { locale } = useRouter();
  const { t } = useTranslation();
  const { sendBIEvent } = useBI();
  const router = useRouter();
  const [articleSearchResults, setArticlesSearchResults] =
    React.useState(searchResults);
  const [articlePopulationStatus, setArticlePopulationStatus] = React.useState<
    Record<string, boolean>
  >({});
  const [pageNumber, setPageNumber] = React.useState(1);
  const [roadmapFilter, setRoadmapFilter] =
    useState<RoadmapFilter>(roadmapInitialFilter);

  const [userFollowedArticles, setUserFollowedArticles] = React.useState<
    string[]
  >([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [triggerBiMetaData, setTriggerBiMetaData] =
    React.useState<SortAndFilterBiMetaData | null>(null);
  const httpClient = useHttpClient();
  const { answersApi, isWixStudioUser } = useContext(Context);
  const context = useContext(Context);
  const isMobileView = isMobile(context);
  const { experiments } = useExperiments({ readOnly: true });
  const breadcrumbsRef = React.useRef(null);
  const marginTop = React.useMemo(() => (isMobileView ? '-54px' : '-72px'), []);
  const scrollState = useIsIntersecting(breadcrumbsRef, marginTop);

  useEffect(() => {
    if (
      experiments.enabled(EXPERIMENTS.SPECS.AUTO_ENROLLMENT) &&
      context.isWixStudioUser
    ) {
      void (async () => {
        const populationIds = articleSearchResults.items
          .map((article) => article.featureEnrollment?.featureId)
          .filter(Boolean) as string[];
        if (populationIds.length) {
          const articlePopulationStatusData =
            (await answersApi.kb.getPopulationStatusBulk({
              populationIds,
            })) as Record<string, boolean>;
          setArticlePopulationStatus(articlePopulationStatusData);
        }
      })();
    }
  }, [articleSearchResults]);

  const togglePopulationStatus = React.useCallback(
    (populationId: string) => {
      setArticlePopulationStatus({
        ...articlePopulationStatus,
        [populationId]: !articlePopulationStatus[populationId],
      });
    },
    [articlePopulationStatus]
  );

  const roadMapLabelsAsList = React.useMemo(
    () =>
      roadmapLabels.map((roadmapLabel) => ({
        name: roadmapLabel.name,
        id: roadmapLabel.id,
      })),
    [roadmapLabels]
  );
  const labelList = [ALL_PRODUCTS_LABEL, ...roadMapLabelsAsList];
  const scrollToTop = () => animateScroll.scrollToTop();

  const updateRoadmapPageData = (data: RoadmapPageData, itemId?: string) =>
    context.setPageTemplateData({
      ...context.pageTemplateData,
      itemId:
        itemId === ALL_PRODUCTS_LABEL_ID
          ? undefined
          : itemId || context.pageTemplateData.itemId,
      pageData: {
        roadmapData: {
          ...context.pageTemplateData.pageData?.roadmapData,
          ...data,
        },
      },
    });

  const pageClickEvent = async (
    clickedItemType: string,
    eventData: EventRoadmapData
  ) => {
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.ROADMAP,
        clicked_item_type: clickedItemType,
        clicked_url: eventData.clickedUrl,
        clicked_text: eventData.clickedText,
        clicked_item_order: eventData.clickedItemOrder,
        clicked_item_id: eventData.clickedItemId,
        item_id: eventData.itemId,
      })
    );
  };

  const sendSortAndFilterBi = useCallback(
    async ({
      filter_by,
      sort_by,
      items_left,
      search_term_filter,
    }: SortAndFilterBiMetaData) => {
      await sendBIEvent(
        sortAndFilter({
          filter_by,
          sort_by,
          items_left,
          source_name: BI.SOURCE_NAMES.ROADMAP,
          search_term_filter,
          kb_lang: locale as string,
        })
      );
    },
    [locale]
  );

  const getFollowedArticles = async () => {
    try {
      const userFollowedArticleResponse =
        await answersApi.articles.getFollowedArticles({
          locale: locale as string,
          page: 1,
          pageSize: 50,
        });
      setUserFollowedArticles(
        userFollowedArticleResponse.items.map((a) => a.id)
      );
    } catch (e: any) {
      setUserFollowedArticles([]);
    }
  };

  useEffect(() => {
    if (context.isLoggedInUser) {
      getFollowedArticles();
    }
  }, [context.isLoggedInUser]);

  const onSearchQueryChange = async (updatedSearchQuery: string) => {
    setPageNumber(1);
    if (updatedSearchQuery.length > 0) {
      setTriggerBiMetaData({
        search_term_filter: updatedSearchQuery,
      });
    }
    setSearchTerm(updatedSearchQuery);
  };

  const onSelectArticle = (article: ArticleSearchResultItem) => {
    const clickedItemOrder = articleSearchResults.items.indexOf(article) + 1;
    pageClickEvent('feature_request_selection', {
      clickedItemId: article.id,
      clickedUrl: article.url,
      clickedText: undefined,
      clickedItemOrder: clickedItemOrder ? `${clickedItemOrder}` : undefined,
    });
  };

  const onChangePageNumber = async (newPageNumber: number) => {
    pageClickEvent('page_selection', {
      clickedUrl: `${location.origin}${router.pathname}`,
      clickedText: `${newPageNumber}`,
    });
    setPageNumber(newPageNumber);
    scrollToTop();
  };

  const followArticle = async (articleId: string) => {
    const followArticles = [...userFollowedArticles];
    try {
      const newFollowedArticles = [...userFollowedArticles, articleId];
      setUserFollowedArticles(newFollowedArticles);
      await answersApi.articles.voteOnIssueOrFeature({
        sourceId: null,
        locale: locale as string,
        id: articleId,
        sourceType: SourceType.HELP_CENTER,
        isProUser: isWixStudioUser,
      });
    } catch {
      setUserFollowedArticles(followArticles);
    }
  };
  const unfollowArticle = async (articleId: string) => {
    const followArticles = [...userFollowedArticles];
    try {
      const newFollowedArticles = userFollowedArticles.filter(
        (item) => item !== articleId
      );
      setUserFollowedArticles(newFollowedArticles);
      await answersApi.articles.unvoteOnIssueOrFeature({
        sourceId: null,
        locale: locale as string,
        id: articleId,
        sourceType: SourceType.HELP_CENTER,
        isProUser: isWixStudioUser,
      });
    } catch {
      setUserFollowedArticles(followArticles);
    }
  };

  const onChangeRoadmapFilter = async (filter: RoadmapFilter) => {
    setPageNumber(1);
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('filterType', `${filter.ETAorVoted}`);
    if (filter.labels.length === 0) {
      newUrl.searchParams.delete('labelIds');
    } else {
      newUrl.searchParams.set(
        'labelIds',
        filter.labels.map((l) => l.id).join(',')
      );
    }

    newUrl.searchParams.set('resolution', `${filter.resolution}`);
    await pushUrl(`${newUrl.pathname}${newUrl.search}`, true);
    updateRoadmapPageData({ roadmapFilter: filter });
    await sendBIEvent(
      sortAndFilter({
        source_name: BI.SOURCE_NAMES.ROADMAP,
        item_id: filter.labels.find(
          (currLabel) => currLabel.id === ALL_PRODUCTS_LABEL_ID
        )
          ? undefined
          : filter.labels.map((l) => l.id).join(','),
        tab: tabToName(filter.resolution),
        filter_by: FilterToName[filter.ETAorVoted],
      })
    );

    setRoadmapFilter(filter);
  };

  const onHomePageClick = async (text: string) => {
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.ROADMAP,
        clicked_item_type: BI.CLICKED_ITEM_TYPES.BREADCRUMBS,
        clicked_url: `${location.origin}/${locale}`,
        clicked_text: text,
      })
    );
  };

  const apply = async (
    newFilter: RoadmapETAorVotedFilter,
    newResolution?: RoadmapFeatureResolution
  ) => {
    setPageNumber(1);

    const updatedFilter = newFilter || roadmapFilter.ETAorVoted;
    const updatedResolution = newResolution || roadmapFilter.resolution;

    setRoadmapFilter({
      ETAorVoted: updatedFilter, resolution: updatedResolution, labels: roadmapFilter.labels,
    });

    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('resolution', `${updatedResolution}`);
    newUrl.searchParams.set('filterType', `${updatedFilter}`);
    await pushUrl(`${newUrl.pathname}${newUrl.search}`, true);
    updateRoadmapPageData({
      roadmapFilter: {
        ETAorVoted: updatedFilter,
        labels: roadmapFilter.labels,
        resolution: updatedResolution,
      },
      resolution: updatedResolution,
    });
    await sendBIEvent(
      sortAndFilter({
        source_name: BI.SOURCE_NAMES.ROADMAP,
        item_id: roadmapFilter.labels.find(
          (currLabel) => currLabel.id === ALL_PRODUCTS_LABEL_ID
        )
          ? undefined
          : roadmapFilter.labels.map((l) => l.id).join(','),
        tab: tabToName(updatedResolution),
        filter_by: FilterToName[updatedFilter],
      })
    );
  };

  const refreshData = async (newPageNumber?: number) => {
    const resolutionEta = getResolutionEtaFilters(roadmapFilter.ETAorVoted);
    const allProductIds = roadMapLabelsAsList.map((l) => l.id);
    const selectedProductsIds = roadmapFilter.labels.map((l) => l.id);

    const labelIds =
      roadmapFilter.labels && roadmapFilter.labels.length > 0 &&
        !selectedProductsIds.includes(ALL_PRODUCTS_LABEL_ID)
        ? selectedProductsIds
        : allProductIds;

    const optedInArticleIds = Object.keys(articlePopulationStatus).filter(
      (id) => articlePopulationStatus[id]
    );
    const followedAndOptedInArticleIds =
      experiments.enabled(EXPERIMENTS.SPECS.AUTO_ENROLLMENT) &&
        context.isWixStudioUser
        ? [...userFollowedArticles, ...optedInArticleIds]
        : userFollowedArticles;
    if (
      roadmapFilter.ETAorVoted === RoadmapETAorVotedFilter.FOLLOWING &&
      !followedAndOptedInArticleIds.length
    ) {
      setArticlesSearchResults({
        items: [],
        itemsCount: 0,
      });
      if (
        triggerBiMetaData?.filter_by ||
        triggerBiMetaData?.sort_by ||
        triggerBiMetaData?.search_term_filter
      ) {
        await sendSortAndFilterBi({
          filter_by: triggerBiMetaData.filter_by,
          sort_by: triggerBiMetaData.sort_by,
          search_term_filter: triggerBiMetaData.search_term_filter,
          items_left: 0,
        });
        setTriggerBiMetaData(null);
      }
    } else {
      const { data } = await httpClient.get<ArticleSearchResult>(
        '/api/article/search',
        {
          params: {
            locale,
            text: searchTerm !== '' ? searchTerm : ' ',
            ids:
              roadmapFilter.ETAorVoted === RoadmapETAorVotedFilter.FOLLOWING
                ? followedAndOptedInArticleIds
                : undefined,
            resolutions:
              roadmapFilter.resolution === 'all'
                ? ALL_RESOLUTIONS
                : [roadmapFilter.resolution as ArticleResolution],
            pageSize: PAGE_SIZE,
            page: newPageNumber || pageNumber,
            hasAnyOfLabelIds: labelIds,
            ...resolutionEta,
            statuses: [ArticleStatus.PUBLISHED, ArticleStatus.DRAFT],
            useVespa: false,
          },
        }
      );
      setArticlesSearchResults({
        ...data,
        items: data.items.slice(0, MAX_SEARCH_RESULTS),
        itemsCount: data.itemsCount > MAX_SEARCH_RESULTS ? MAX_SEARCH_RESULTS : data.itemsCount,
      });
      if (
        triggerBiMetaData?.filter_by ||
        triggerBiMetaData?.sort_by ||
        triggerBiMetaData?.search_term_filter
      ) {
        await sendSortAndFilterBi({
          filter_by: triggerBiMetaData.filter_by,
          sort_by: triggerBiMetaData.sort_by,
          search_term_filter: triggerBiMetaData.search_term_filter,
          items_left: data.itemsCount,
        });
        setTriggerBiMetaData(null);
      }
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      refreshData();
    }
  }, [pageNumber, roadmapFilter, searchTerm, sendSortAndFilterBi]);

  useEffect(() => {
    if (roadmapFilter.ETAorVoted === RoadmapETAorVotedFilter.FOLLOWING) {
      refreshData();
    }
  }, [userFollowedArticles]);

  return locale === LOCALES.EN ? (
    <>
      <div className={css.breadcrumbsRoadmap}>
        <Breadcrumbs
          breadcrumbItems={[
            {
              id: '1',
              label: t('roadmap.breadcrumbs'),
            },
          ]}
          onHomePageClick={onHomePageClick}
          shallow
        />
      </div>
      <div ref={breadcrumbsRef}></div>
      <Box
        className={`${css.roadmapPageWrapper} ${css.roadmapV2}`}
        width="100%"
        direction="horizontal"
        align="center"
      >
        {articleSearchResults.items && (
          <RoadmapArticleList
            roadmapFilter={roadmapFilter}
            articleSearchResult={articleSearchResults}
            followArticle={followArticle}
            unfollowArticle={unfollowArticle}
            currentPage={pageNumber}
            onChangePage={onChangePageNumber}
            onSelectArticle={onSelectArticle}
            locale={locale || 'en'}
            onChangeRoadmapFilter={onChangeRoadmapFilter}
            userFollowedArticle={userFollowedArticles}
            labels={labelList}
            apply={apply}
            articlePopulationStatus={articlePopulationStatus}
            togglePopulationStatus={togglePopulationStatus}
            onSearch={onSearchQueryChange}
            searchTerm={searchTerm}
            isScrolled={isSledUserAgent() ? false : scrollState}
            onChangeTriggerBiMetaData={(
              updatedTriggerBiMetaData: SortAndFilterBiMetaData
            ) => setTriggerBiMetaData(updatedTriggerBiMetaData)}
          />
        )}
      </Box>
    </>
  ) : (
    <Error statusCode={404} />
  );
};

export const getServerSideProps = withAppServerProps(
  async ({ req, res, locale, query }) => {
    const {
      resolution: resolutionFromQuery,
      labelId,
      sortType,
      filterType,
      labelIds,
    } = query;

    const resolutionAsEnum = Number(resolutionFromQuery) as ArticleResolution;
    const resolution =
      resolutionFromQuery && resolutionFromQuery !== 'all'
        ? resolutionAsEnum
        : 'all';
    const resolutions =
      resolution && resolution !== 'all' ? [resolutionAsEnum] : ALL_RESOLUTIONS;

    const allLabels = isProduction()
      ? await getLabels(req.aspects, res.locals.redisClient)
      : MOCKED_ROADMAP_LABELS;

    const roadmapLabels = getRoadmapLabels(allLabels as Label[]);
    const allProductIds = roadmapLabels.map((label) => label.id);
    const labelIdFromQuery =
      labelId && Array.isArray(labelId) ? labelId[0] : labelId;

    const labelIdsFromQuery =
      labelIds && Array.isArray(labelIds) ? labelIds : labelIds?.split(',');

    const labels = labelIdsFromQuery
      ? labelIdsFromQuery.map((id) => {
        const name = roadmapLabels.find((l) => l.id === id)?.name || '';
        return { id: id as string, name: name as string };
      })
      : [ALL_PRODUCTS_LABEL];

    const labelsV2 =
      labels.length === 1 && labels[0].id === ALL_PRODUCTS_LABEL_ID
        ? undefined
        : labels.filter((l) => l.id !== ALL_PRODUCTS_LABEL_ID);

    const labelName =
      labelIdFromQuery && TEST_LABEL_ID.includes(labelIdFromQuery)
        ? 'Test Label'
        : roadmapLabels.find((l) => l.id === labelIdFromQuery)?.name;

    const labelFromUrl =
      labelId && labelName && labelId !== ALL_PRODUCTS_LABEL_ID
        ? { id: labelId as string, name: labelName as string }
        : ALL_PRODUCTS_LABEL;

    const sortFormUrl =
      sortType && !Array.isArray(sortType)
        ? (Number(sortType) as ArticleSortType)
        : ArticleSortType.LAST_PUBLISH_DATE_DESC;

    const filterFormUrl =
      filterType && !Array.isArray(filterType)
        ? (Number(filterType) as RoadmapETAorVotedFilter)
        : RoadmapETAorVotedFilter.ALL;

    let searchResults;
    const isTestLabel =
      labelIdFromQuery && TEST_LABEL_ID.includes(labelIdFromQuery);
    if (isTestLabel) {
      searchResults =
        (await adminSearchArticles(
          req.aspects,
          locale,
          {
            text: ' ',
            resolutions,
            pageSize: PAGE_SIZE,
            page: 1,
            hasAnyOfLabelIds: [labelIdFromQuery],
            sortType: sortFormUrl,
            statuses: [ArticleStatus.PUBLISHED, ArticleStatus.DRAFT],
          },
          false
        )) || [];
    } else {
      searchResults =
        (await searchArticles(
          req.aspects,
          locale,
          {
            text: '',
            resolutions,
            pageSize: PAGE_SIZE,
            page: 1,
            hasAnyOfLabelIds: labelsV2 && labelsV2.length > 0
              ? labelsV2.map((l) => l.id)
              : allProductIds,
            sortType: sortFormUrl,
          },
          res.locals.redisClient,
          false
        )) || [];
    }
    return {
      props: {
        searchResults: {
          ...searchResults,
          items: searchResults.items.slice(0, MAX_SEARCH_RESULTS),
          itemsCount: searchResults.itemsCount > MAX_SEARCH_RESULTS ? MAX_SEARCH_RESULTS : searchResults.itemsCount,
        },
        pageType: PAGES.ROADMAP,
        pageData: {
          roadmapData: {
            resolution,
            filterType: filterFormUrl,
            label: labelFromUrl,
            roadmapLabels,
          },
        },
        itemId:
          labelFromUrl.id === ALL_PRODUCTS_LABEL_ID ? null : labelFromUrl.id,
        roadmapLabels,
        roadmapInitialFilter: {
          ETAorVoted: filterFormUrl,
          resolution,
          labels: (isTestLabel ? [labelFromUrl] : labels) || [
            ALL_PRODUCTS_LABEL,
          ],
        },
      },
    };
  }
);

export default RoadmapPage;
