import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  ALL_PRODUCTS_LABEL_ID,
  BI,
  EXPERIMENTS,
  LOCALES,
  PAGES,
} from '../../constants';
import { useBI } from '../../hooks/useBI';
import { PageData, PageDataArticle } from '../PageTemplate';
import {
  CustomWindow,
  PageType,
  RoadmapETAorVotedFilter,
  RoadmapFeatureResolution,
} from '../../types';
import { useHistoryContext } from '../../hooks/useRouteHistory';
import { pageView } from '@wix/bi-logger-new-help-center/v2';
import {
  mapArticleTypeToItemType,
  mapArticleVersionNumberToLabel,
} from '../../utils/bi';
import { FilterToName, tabToName } from '../../utils/roadmap';
import { AnalyticsLogger, SourceType } from '@wix/answers-api';
import { useExperiments, useHttpClient } from '@wix/fe-essentials-standalone';
import {
  firstAnonymous,
  pageView as pageViewPblc,
} from '@wix/bi-logger-pblc/v2';
import { hasWixClientId, isPersonalPage } from '../../utils/login';

declare const window: CustomWindow;

type PageViewProps = {
  pageType: PageType;
  pageData: PageData;
  itemId?: string;
};

export const PageView: FunctionComponent<PageViewProps> = ({
  pageData,
  pageType,
  itemId,
}) => {
  const { sendBIEvent } = useBI();
  const router = useRouter();
  const { prePageUrl } = useHistoryContext();
  const { locale } = router;
  const { experiments } = useExperiments({ readOnly: true });
  const httpClient = useHttpClient();

  const getArticleFeedbackVersion = (articleType?: number) =>
    mapArticleTypeToItemType(articleType) === BI.ITEM_TYPES.ARTICLE
      ? 'old_feedback_form'
      : undefined;

  const getSubheaderVersion = () => {
    const isNewSubheaderEnabled = experiments.enabled(
      EXPERIMENTS.SPECS.NEW_SUBHEADER_DESIGN
    );

    return isNewSubheaderEnabled
      ? 'new_subheader_design'
      : 'old_subheader_design';
  };

  const getSearchResultsPageVersion = () => {
    const isNewSearchResultsPageOpenToAll = experiments.enabled(
      EXPERIMENTS.SPECS.NEW_SEARCH_RESULTS_PAGE_ALL_LOCALES
    );

    return isNewSearchResultsPageOpenToAll || locale === LOCALES.EN
      ? 'new_search_results'
      : 'old_search_results';
  };

  const getReadingTimeVersion = () => 'reading_time_true';

  const articlePageViewItemVersion = (article: PageDataArticle) => {
    const version = [
      getArticleFeedbackVersion(article.type),
      getReadingTimeVersion(),
      getSearchResultsPageVersion(),
      getSubheaderVersion(),
    ];

    const itemVersions = version.filter(Boolean);
    return itemVersions.length > 0 ? itemVersions.join(', ') : undefined;
  };

  const sendPageViewEvent = useCallback(async (): Promise<void> => {
    const emptyPageData = Object.keys(pageData).length === 0;
    const urlSearchParams = new URLSearchParams(location.search);
    const { innerWidth: screenWidth, innerHeight: screenHeight } = window;

    if (!hasWixClientId()) {
      await sendBIEvent(
        firstAnonymous({
          app_url: `${location.origin}${location.pathname}`,
          _artificial_ts: new Date().getTime(),
          // @ts-expect-error wrong type
          utm_campaign: urlSearchParams.get('utm_campaign'),
          // @ts-expect-error wrong type
          experiment_id: urlSearchParams.get('experiment_id'),
          referral: encodeURIComponent(document.referrer),
          _artifact_name: 'support',
        })
      );
    } else {
      await sendBIEvent(
        pageViewPblc({
          app_url: `${location.origin}${location.pathname}`,
          _artificial_ts: new Date().getTime(),
          // @ts-expect-error wrong type
          utm_campaign: urlSearchParams.get('utm_campaign'),
          // @ts-expect-error wrong type
          experiment_id: urlSearchParams.get('experiment_id'),
          referral: encodeURIComponent(document.referrer),
          _referer: location.href,
          _artifact_name: 'support',
        })
      );
    }

    const commonBiData = {
      kb_lang: locale as string,
      referral_url: prePageUrl,
      screen_width: screenWidth,
      screen_height: screenHeight,
    };

    switch (pageType) {
      case PAGES.ARTICLE:
        if (itemId && pageData?.article) {
          await sendBIEvent(
            pageView({
              ...commonBiData,
              source_name: BI.SOURCE_NAMES.ARTICLE,
              item_id: itemId,
              item_type: mapArticleTypeToItemType(pageData.article.type),
              ab_test_version: mapArticleVersionNumberToLabel(
                pageData.article.alternativeVersionNumber,
                pageData.article.abTestInfo
              ),
              item_version: articlePageViewItemVersion(pageData.article),
              page_load_at_top: window?.scrollY < 300 ? true : false,
            })
          );

          // Special for Answers BI
          try {
            if (location.hostname === 'localhost') {
              return;
            }
            AnalyticsLogger.logPageView(
              (url: string, data: unknown) =>
                httpClient.post(`${location.origin}${url}`, data),
              '/api/v1',
              pageData.article.locale || '',
              SourceType.HELP_CENTER,
              null,
              pageData.article.id || ''
            );
          } catch (e) {
            console.error('Failed to send page view event to Answers BI', e);
          }
        }
        break;
      case PAGES.SEARCH_RESULTS:
        if (
          pageData?.searchResults &&
          pageData?.searchResults.items?.length > 0
        ) {
          const term = new URLSearchParams(location.search).get('term') || '';
          await sendBIEvent(
            pageView({
              ...commonBiData,
              source_name: BI.SOURCE_NAMES.SEARCH_RESULTS,
              question: term as string,
              item_version: `${getSearchResultsPageVersion()}, ${getSubheaderVersion()}`,
              presented_content: JSON.stringify(
                pageData?.searchResults.items.map((article, index) => ({
                  order: index + 1,
                  item_id: article.id,
                  locale,
                  type: mapArticleTypeToItemType(article.type),
                }))
              ),
            })
          );
        }
        break;
      case PAGES.CATEGORY:
        if (itemId && pageData.category) {
          await sendBIEvent(
            pageView({
              ...commonBiData,
              source_name: BI.SOURCE_NAMES.CATEGORY,
              item_id: itemId,
              referral_url: prePageUrl,
              item_version: `${getSearchResultsPageVersion()}, ${getSubheaderVersion()}`,
            })
          );
        }
        break;
      case PAGES.KNOWN_ISSUES:
        if (itemId && pageData.category) {
          await sendBIEvent(
            pageView({
              ...commonBiData,
              source_name: BI.SOURCE_NAMES.KNOWN_ISSUES,
              item_id: itemId,
              referral_name: router.query?.referral as string,
              item_version: getSearchResultsPageVersion(),
            })
          );
        }
        break;
      case PAGES.HOMEPAGE:
        await sendBIEvent(
          pageView({
            ...commonBiData,
            source_name: BI.SOURCE_NAMES.HOME,
            referral_name: router.query?.utm_source as string,
            item_version: `new_home_page, ${getSearchResultsPageVersion()}`,
          })
        );
        break;
      case PAGES.TICKET:
        if (itemId && emptyPageData) {
          await sendBIEvent(
            pageView({
              ...commonBiData,
              source_name: BI.SOURCE_NAMES.TICKET,
              item_id: itemId as string,
              item_type: BI.ITEM_TYPES.TICKET,
            })
          );
        }
        break;
      case PAGES.PROFILE:
        if (itemId && emptyPageData) {
          await sendBIEvent(
            pageView({
              ...commonBiData,
              source_name: BI.SOURCE_NAMES.PROFILE,
              item_id: itemId as string,
              item_type: BI.ITEM_TYPES.PROFILE,
            })
          );
        }
        break;
      case PAGES.ROADMAP:
        if (pageData.roadmapData) {
          await sendBIEvent(
            pageView({
              ...commonBiData,
              source_name: BI.SOURCE_NAMES.ROADMAP,
              item_id:
                pageData.roadmapData?.label?.id === ALL_PRODUCTS_LABEL_ID
                  ? undefined
                  : (pageData.roadmapData?.label?.id as string),
              tab: tabToName(
                pageData.roadmapData?.resolution as RoadmapFeatureResolution
              ),
              filter_by:
                FilterToName[
                pageData.roadmapData?.roadmapFilter
                  ?.ETAorVoted as RoadmapETAorVotedFilter
                ],
              referral_name: router.query?.referral as string,
            })
          );
        }
        break;
      case PAGES.CONTACT:
        await sendBIEvent(
          pageView({
            ...commonBiData,
            source_name: BI.SOURCE_NAMES.CONTACT,
            item_name: BI.ITEM_TYPES.CONTACT,
          })
        );
        break;
      default:
        break;
    }
  }, [locale, pageData, pageType, prePageUrl, itemId]);

  useEffect(() => {
    void (async () => {
      await sendPageViewEvent();
    })();
    const postSignOut = isPersonalPage(pageType)
      ? `${location.origin}/account/logout?redirectUrl=${location.origin}${locale}`
      : `${location.origin}/account/logout?redirectUrl=${location.href}`;

    const updateHeaderFooterLinks = () => {
      if (window.HeaderAPI) {
        window.HeaderAPI.updateLinks({
          postLogin: location.href,
          postSignOut,
          postSignUp: location.href,
        });
      }
    };

    let updateHeaderFooterLinksInterval: NodeJS.Timeout;
    if (!window.HeaderAPI) {
      updateHeaderFooterLinksInterval = setInterval(() => {
        if (window.HeaderAPI) {
          updateHeaderFooterLinks();
          clearInterval(updateHeaderFooterLinksInterval);
        }
      }, 150);
    } else {
      updateHeaderFooterLinks();
    }
  }, [itemId]);
  return <></>;
};
