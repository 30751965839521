import { ArticleSourceType, PageType, RequestAFeatureModalStep } from './../types';
import { BI, PAGES } from '../constants';
import { ArticleType } from '@wix/answers-api';
import { ABTestInfo } from '@wix/ambassador-answers-search-proxy/types';

export const mapArticleTypeToItemType = (
  articleType: ArticleType | undefined
): string => {
  if (articleType === undefined) {
    return '';
  }
  switch (articleType) {
    case ArticleType.FEATURE_REQUEST:
      return BI.ITEM_TYPES.FEATURE_REQUEST;
    case ArticleType.KNOWN_ISSUE:
      return BI.ITEM_TYPES.KNOWN_ISSUE;
    case ArticleType.ARTICLE:
      return BI.ITEM_TYPES.ARTICLE;
    default:
      return BI.ITEM_TYPES.ARTICLE;
  }
};

export const mapResourceTypeToItemType = (
  resourceType: ArticleSourceType
): string => {
  switch (resourceType) {
    case ArticleType.FEATURE_REQUEST:
      return BI.ITEM_TYPES.FEATURE_REQUEST;
    case ArticleType.KNOWN_ISSUE:
      return BI.ITEM_TYPES.KNOWN_ISSUE;
    case ArticleType.ARTICLE:
      return BI.ITEM_TYPES.ARTICLE;
    default:
      return '';
  }
};

export const mapPageTypeToSourceName = (pageType: PageType): string => {
  switch (pageType) {
    case PAGES.SEARCH_RESULTS:
      return BI.SOURCE_NAMES.SEARCH_RESULTS;
    case PAGES.ROADMAP:
      return BI.SOURCE_NAMES.ROADMAP;
    case PAGES.CATEGORY:
      return BI.SOURCE_NAMES.CATEGORY;
    case PAGES.TICKET:
      return BI.SOURCE_NAMES.TICKET;
    case PAGES.HOMEPAGE:
      return BI.SOURCE_NAMES.HOME;
    case PAGES.PROFILE:
      return BI.SOURCE_NAMES.PROFILE;
    case PAGES.ARTICLE:
    default:
      return BI.SOURCE_NAMES.ARTICLE;
  }
};

export const mapArticleVersionNumberToLabel = (
  articleVersion?: number,
  abTestInfo?: ABTestInfo
): string | undefined => {
  if (articleVersion === undefined || !abTestInfo || !abTestInfo.experimentId) {
    return undefined;
  }
  return articleVersion === 0 ? 'B' : 'A';
};

export const mapReferralUrlToSourceName = (
  referralUrl = '',
  locale: string
): string | undefined => {
  if (!referralUrl.startsWith(location.origin)) {
    return undefined;
  }
  let relativePath = referralUrl.replace(location.origin, '');

  if (
    relativePath === '' ||
    relativePath === '/' ||
    relativePath.endsWith(`/${locale}/`) ||
    relativePath.endsWith(`/${locale}`)
  ) {
    return BI.SOURCE_NAMES.HOME;
  }

  if (relativePath.startsWith(`/${locale}/`)) {
    relativePath = relativePath.replace(`/${locale}/`, '');
  }
  if (relativePath.startsWith('/')) {
    relativePath = relativePath.replace('/', '');
  }
  switch (true) {
    case relativePath.startsWith('articles/') ||
      relativePath.startsWith('article/'):
      return BI.SOURCE_NAMES.ARTICLE;
    case relativePath.startsWith('contact'):
      return BI.SOURCE_NAMES.CONTACT;
    case relativePath.startsWith('roadmap'):
      return BI.SOURCE_NAMES.ROADMAP;
    case relativePath.includes('search?'):
      return BI.SOURCE_NAMES.SEARCH_RESULTS;
    case relativePath.startsWith('ticket/'):
      return BI.SOURCE_NAMES.TICKET;
    case relativePath.startsWith('user/'):
      return BI.SOURCE_NAMES.PROFILE;
    case relativePath.startsWith('known-issues'):
      return BI.SOURCE_NAMES.KNOWN_ISSUES;
    case relativePath.split('/').length === 2:
      return BI.SOURCE_NAMES.CATEGORY;
    default:
      return undefined;
  }
};

export function sessionDebounceBI<T extends (...args: any[]) => any>(
  func: T,
  wait: number
) {
  let timeout: ReturnType<typeof setTimeout>;
  const resolveList: ((value: ReturnType<T>) => void)[] = [];

  return (...args: Parameters<T>) =>
    new Promise<Awaited<ReturnType<T>>>((resolve) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      resolveList.push(resolve);

      timeout = setTimeout(async () => {
        const result = await func(...args);
        while (resolveList.length) {
          resolveList.shift()?.(result);
        }
      }, wait);
    });
}

export const REQUEST_A_FEATURE_STEP_TO_SCREEN_NAME: Record<string, string> = {
  [RequestAFeatureModalStep.REQUEST_A_FEATURE]: BI.SCREEN_NAME.REQUEST_A_NEW_FEATURE,
  [RequestAFeatureModalStep.LOADING_EXISTING_FEATURES]: BI.SCREEN_NAME.LOADER,
  [RequestAFeatureModalStep.LOADING_SUBMITTING]: BI.SCREEN_NAME.LOADER,
  [RequestAFeatureModalStep.SELECT_EXISTING_FEATURE]: BI.SCREEN_NAME.FEATURE_ALREADY_EXISTS,
  [RequestAFeatureModalStep.FEATURE_REQUEST_ALREADY_EXISTS]: BI.SCREEN_NAME.ALTERNATIVE_FEATURE_REQUEST,
};
