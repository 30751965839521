import { useCallback, useContext } from 'react';
import moment from 'moment';
import { Context } from '../context';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { LogBI, SessionBIType } from '../types';
import { EXPERIMENTS } from '../constants';
import { HttpClient } from '@wix/http-client';
import { RandomGenerator } from '@wix/answers-api';

export const useSessionBI = () => {
  const { experiments } = useExperiments({ readOnly: true });

  const { sessionBI, setSessionBI, isLoggedInUser, fetchSessionDebounceBI } =
    useContext(Context);

  const getSessionBI = useCallback(
    (_: LogBI) =>
      new Promise<string | null>((resolve) => {
        if (location.hostname === 'localhost') {
          return RandomGenerator.id();
        }
        const isUnifiedSessionEnabled = experiments.enabled(
          EXPERIMENTS.SPECS.UNIFIED_SESSION
        );
        if (!isUnifiedSessionEnabled) {
          return resolve(null);
        }
        const { lastSessionDate, selfServiceSessionId } = sessionBI;
        const diffSeconds = moment().diff(lastSessionDate, 'seconds');
        if (!selfServiceSessionId || diffSeconds > 20) {
          fetchSessionDebounceBI(isLoggedInUser)
            .then((value) => {
              setSessionBI({
                lastSessionDate: moment(),
                selfServiceSessionId: value,
              });
              resolve(value);
            })
            .catch(() => resolve(null));
        } else {
          resolve(selfServiceSessionId);
        }
      }),
    [sessionBI.lastSessionDate, sessionBI.selfServiceSessionId]
  );

  return { getSessionBI };
};

export const fetchSessionBI = async (
  isLoggedInUser: boolean
): Promise<string | null> => {
  let isConvertFlow = false;
  if (isLoggedInUser) {
    const sessionType = localStorage.getItem('hc_session_bi_type');
    if (sessionType === SessionBIType.VISITOR) {
      localStorage.setItem('hc_session_bi_type', SessionBIType.USER);
      isConvertFlow = true;
    }
  }

  const httpClient = new HttpClient();
  return httpClient
    .put<{ helpWidgetSession: { sessionId: string } }>(
      `https://support.wix.com/_serverless/cc-user-facing-service/bi/session?flow=${isConvertFlow ? 'convert' : 'regular'
      }`
    )
    .then((res) => res.data.helpWidgetSession.sessionId)
    .catch(() => null);
};
