import type { Article as SearchProxyArticle } from '@wix/ambassador-answers-search-proxy/types';
import {
  ArticleSortType,
  Category,
  Label,
  Translation,
} from '@wix/answers-api';
import { Box } from '@wix/design-system';
import React, { FunctionComponent, useContext } from 'react';
import { EXPERIMENTS, PAGES } from '../../constants';
import { Context } from '../../context';
import {
  ArticleSearchResult,
  PageSeo,
  PageType,
  RoadmapFeatureResolution,
  RoadmapFilter,
} from '../../types';
import { AgentToolbar } from '../AgentToolbar';
import { PageView } from '../PageView';
import { SEO } from '../SEO';
import { SubHeader } from '../SubHeader';
import { UserEngagement } from '../UserEngagement';
import css from './index.module.scss';
import { useExperiments } from '@wix/fe-essentials-standalone';
import classNames from 'classnames';

export type PageDataArticle = SearchProxyArticle & {
  withRelatedArticles?: boolean;
};

export type PageData = {
  article?: PageDataArticle;
  category?: Category;
  translations?: Translation[];
  roadmapData?: RoadmapPageData;
  searchResults?: ArticleSearchResult;
  term?: string;
};

export type RoadmapPageData = {
  sortType?: ArticleSortType;
  roadmapFilter?: RoadmapFilter;
  resolution?: RoadmapFeatureResolution;
  roadmapLabels?: Label[];
  label?: { name: string; id: string };
};

export type PageTemplateData = {
  pageData?: PageData;
  itemId?: string;
  itemType?: string;
  hasError?: boolean;
};

type PageTemplateProps = {
  children: React.ReactNode;
  pageType: PageType;
  pageSeo: PageSeo;
};

export const PageTemplate: FunctionComponent<PageTemplateProps> = ({
  children,
  pageType,
  pageSeo,
}) => {
  const { pageTemplateData } = useContext(Context);

  const {
    pageData = {},
    itemId,
    itemType,
    hasError = false,
  } = pageTemplateData;

  const PAGES_WITHOUT_SUBHEADER = [PAGES.HOMEPAGE, PAGES.KNOWN_ISSUES];
  const shouldShowSubHeader =
    !PAGES_WITHOUT_SUBHEADER.includes(pageType) && !hasError;
  const { experiments } = useExperiments({ readOnly: true });
  const isNewSubheader = experiments.enabled(
    EXPERIMENTS.SPECS.NEW_SUBHEADER_DESIGN
  );

  return (
    <Box
      direction="vertical"
      className={classNames('page-template', css.wrapper, {
        [css.newSubheader]: isNewSubheader,
      })}
    >
      <SEO pageData={pageData} pageType={pageType} pageSeo={pageSeo} />
      <AgentToolbar pageData={pageData} pageType={pageType} />
      {shouldShowSubHeader && (
        <SubHeader pageType={pageType} itemId={itemId} itemType={itemType} />
      )}
      <UserEngagement
        pageData={pageData}
        pageType={pageType}
        itemType={itemType as string}
        itemId={itemId}
      />
      <PageView pageData={pageData} pageType={pageType} itemId={itemId} />
      {children}
    </Box>
  );
};
